import styled from 'styled-components'

import Modal from 'react-modal'

export const ModalContainer = styled(Modal)`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3D(-50%, -50%, 0);
  background: #ffffff;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  outline: none;
  width: calc(100% - 20px);
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : '584px')};
  max-height: 90%;
  overflow: auto;
  -webkit-overflow-scrolling: touch !important;
`
