import React, { useRef, useEffect } from 'react'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { ModalContainer } from './style'

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 10000,
    backgroundColor: `rgba(0, 0, 0, 0.6)`,
    overflow: 'auto',
  },
}

const Modal = function({ children, className, isOpen }) {
  const popUpRef = useRef(null)
  const targetElement = popUpRef.current

  useEffect(() => {
    if (isOpen) {
      // setTimeout(() => disableBodyScroll(targetElement), 100)
    } else {
      enableBodyScroll(targetElement)
    }
  }, [isOpen])

  return (
    <ModalContainer
      isOpen={isOpen}
      style={customStyles}
      ref={popUpRef}
      ariaHideApp={false}
      className={`${className} modal`}
    >
      {children}
    </ModalContainer>
  )
}

export default Modal
