import styled from 'styled-components'

export const InviteButton = styled.button`
  background: #965f47;
  padding: 20px 0 18px 0;
  border: none;
  outline: none;
  width: calc(100% + 30px);
  margin-left: -30px;
  margin-bottom: 40px;
  position: relative;
  display: flex;
  justify-content: space-between;
  transition: 100ms;
  cursor: pointer;

  :hover {
    background: #7a4d39;
  }
  h3 {
    font-size: 17px !important;
    text-transform: uppercase;
    color: white !important;
    display: block !important;
    margin: 0 !important;
    text-align: left;
    margin-left: 23px !important;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 60px;
    height: 100%;
    width: 1px;
    background: white;
    opacity: 0.3;
  }

  svg {
    height: 23px;
    width: 23px;
    margin-right: 20px;
  }

  &.alt {
    margin-left: auto;
    width: 235px;
  }
`

export const InviteFormWrapper = styled.div`
  background: white;
`

export const Header = styled.div`
  height: 80px;
  display: flex;
  align-items: center;
  background: black;
  position: relative;

  .avenueImg {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (min-width: 1024px) {
    height: 137px;
  }
  h3 {
    font-size: 18px;
    text-transform: uppercase;
    color: white;
    display: block !important;
    margin: 0;
    text-align: left;
    margin-left: 20px;

    @media (min-width: 1024px) {
      margin-left: 30px;
      font-size: 32px;
    }
  }
`

export const MainSection = styled.div`
  /* display: flex;
  flex-direction: column; */
  padding: 10px 15px;
  overflow: hidden;

  @media (min-width: 1024px) {
    padding: 25px 40px;
  }

  p {
    span {
      font-weight: 500;
    }

    &.bigger {
      @media (min-width: 1024px) {
        font-size: 20px;
      }
    }
  }

  h3 {
    display: block;
    font-weight: 900;
    font-size: 20px;
    line-height: 130%;
    text-transform: uppercase;
    color: #232c20;
    margin-bottom: 10px;
  }
`

export const Visual = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  background: #545c52;
  padding-right: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  > .avenueImg {
    width: 97px;
    height: 67px;
    margin-right: 20px;
    background: teal;
  }

  font-size: 14px;
  color: white;
  font-weight: bold;
  font-family: Helvetica, sans-serif;
`

export const Sent = styled.p`
  color: ${({ theme }) => theme.primary};
  display: ${props => (props.disabled ? 'flex' : 'none')};
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 20px;
  font-size: 17px;
  line-height: 134%;
  font-weight: 500;
  align-items: center;

  @media (min-width: 768px) {
    font-size: 20px;
  }
`

export const Disclaimer = styled.p`
  font-size: 12px !important;
  padding-bottom: 20px;
  position: relative;

  a {
    font-size: inherit !important;
    font-weight: 500 !important;
    outline: none;

    &:focus {
      text-decoration: underline;
    }
  }
  &.border {
    margin-bottom: 20px;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 700px;
      height: 1px;
      background: #e5e5e5;
    }
  }
  @media (min-width: 1024px) {
    font-size: 14px !important;
  }
`

export const InputWrapper = styled.div`
  width: 100%;
  margin-right: 20px;
  display: ${props => (props.disabled ? 'none' : 'grid')};
  grid-template-columns: 1fr;
  grid-gap: 5px;

  font-size: 16px;
  color: #212c1f;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  margin-bottom: 10px;

  @media (min-width: 768px) {
    grid-gap: 20px;
    margin-bottom: 15px;
  }
  @media (min-width: 1024px) {
    max-width: 100%;
    font-weight: 300px;
    font-size: 20px;
    color: #545c52;
    letter-spacing: 0;
    line-height: 30px;
  }
`

export const Form = styled.form`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    /* -webkit-box-shadow: 0 0 0 30px #212c1f inset !important; */
    /* -webkit-text-fill-color: ${({ theme }) => theme.secondary} !important; */
  }
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  display: ${props => props.disabled && 'none'};

  @media (min-width: 768px) {
    justify-content: center;
  }
  input {
    display: ${props => (props.disabled ? 'none' : 'inline')};

    color: #545c52;
    line-height: 30px;
    font-size: 18px;
    background: none;
    outline: none;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.primary};
    border-radius: 0;
    padding-bottom: 5px;
    margin-bottom: 8px;

    ::placeholder {
      opacity: 1;
    }

    @media (min-width: 768px) {
      padding-bottom: 10px;
      margin-bottom: 0px;
    }
    @media (min-width: 1024px) {
      font-size: 20px;

      padding-bottom: 7px;
    }

    @media (min-width: 1366px) {
      margin-left: 0;
    }

    :last-of-type {
      border-bottom: ${props =>
        props.showError
          ? '1px solid #A7444D'
          : '1px solid ${({ theme }) => theme.primary}'};
    }
  }
`

export const SpreadIt = styled.div`
  display: flex;
  position: relative;
  padding-top: 25px;

  @media (min-width: 1024px) {
    padding-top: 40px;
    margin-bottom: -5px;
  }
  h4 {
    font-weight: 900;
    font-size: 14px;
    line-height: 19px;
    text-transform: uppercase;
    color: #232c20;
    display: flex;
    align-items: center;

    > a {
      font-family: inherit !important;
      font-weight: inherit !important;
      font-size: inherit !important;
      text-decoration: underline !important;
      color: #965f47 !important;
      margin-left: 5px;
    }
  }

  svg {
    margin-left: 15px;
    margin-top: -2px;
    flex-shrink: 0;
    path {
      fill: #965f47;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 700px;
    height: 1px;
    background: #e5e5e5;
  }
`

export const Close = styled.button`
  height: 32px;
  width: 32px;
  background: #232c20;
  padding: 0;
  z-index: 23;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 100ms;
  opacity: 1;
  z-index: 10;
  :hover {
    opacity: 0.7;
  }
  svg {
    path {
      fill: white;
    }
  }
`

export const ErrorMessage = styled.p`
  font-weight: 400;
  color: #FFFFFF;
  background:  #a7444d;
  padding: 0 8px;
  /* position: absolute; */
  /* bottom: -44px; */
  font-size: 12px;
  width: 100%;
  margin-bottom:0 !important;
 

  /* @media (min-width: 768px) {
    bottom: -47px;
  } */
  /* @media (min-width: 1024px) {
    bottom: ${props => (props.variation ? ' -55px' : '-38px')};

    left: ${props => (props.variation ? ' 0' : '10px')};
  }

  @media (min-width: 1366px) {
    left: 0;
  } */
`
