import moment from 'moment'
import { sortBy } from 'lodash'

const eventDate = dates => {
  if (dates.length > 1) {
    let sorted = sortBy(dates)

    const first = sorted[0].date.split('-')
    const firstNew = new Date(first[2], first[1] - 1, first[0])

    const last = sorted[sorted.length - 1].date.split('-')
    const lastNew = new Date(last[2], last[1] - 1, last[0])

    let from = moment(firstNew).format('DD MMM YYYY')
    let to = moment(lastNew).format('DD MMM YYYY')

    if (from === to) return from
    return `${from} - ${to}`
  }

  if (dates.length === 1) {
    const one = dates[0].date.split('-')
    const oneNew = new Date(one[2], one[1] - 1, one[0])

    return moment(oneNew).format('ddd DD MMM YYYY')
  }

  return null
}

export { eventDate }
