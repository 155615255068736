import React from 'react'
import { eventDate } from '../../utils/eventDates'
import { checkSite, textTruncate } from '../../utils/helpers'

import addressIcon from '../../images/contact-svg.svg'
import clockIcon from '../../images/clock-icon.svg'
import ticketsIcon from '../../images/ticket.svg'
import { ReactComponent as CalendarIcon } from '../../images/calendar.svg'
import cursorIcon from '../../images/cursor-icon.svg'
import mailIcon from '../../images/mail-icon.svg'
import fileIcon from '../../images/file-icon.svg'
import FancyLink from '../../utils/FancyLink.js'
import { InviteAFriend } from './InviteAFriend'

import {
  ContactInformationContainer,
  Info,
  ContactInformationWrapper,
  Address,
  Venue,
  Hours,
  Contacting,
  WebsiteInfo,
  Links,
  Pricing,
  Event,
  MoreInfo,
  Dates,
  Time,
  Details,
  Tickets,
  Contact,
  ItineraryEmail,
  ItineraryFile,
} from './style.js'
import FavouriteButton from '../FavouriteButton/FavouriteButton'
import RegionTag from '../Teaser/RegionTag'

const ContactInformation = props => {
  const hasAll = props.openingHours?.filter(day => day.day === 'ALL')
  let allDays = []
  const addDaily = () => {
    let allTimes = []
    hasAll.map(time => {
      allTimes = [...allTimes, time.times]
    })

    allDays = [
      { day: 'SUN', times: allTimes },
      { day: 'MON', times: allTimes },
      { day: 'TUE', times: allTimes },
      { day: 'WED', times: allTimes },
      { day: 'THU', times: allTimes },
      { day: 'FRI', times: allTimes },
      { day: 'SAT', times: allTimes },
    ]

    allDays.map(day => {
      props.openingHours.map(singleDay => {
        if (day.day === singleDay.day) {
          if (day.day > singleDay.day) {
            return (day.times = day.times + `, ${singleDay.times}`)
          }
          return (day.times = [`${singleDay.times}, ` + day.times.join(', ')])
        }
      })
    })
  }

  const dayWithStartTime =
    props.event && props.dates.find(obj => obj.startTime !== null)

  if (hasAll) {
    addDaily()
  }

  return (
    <ContactInformationContainer {...props}>
      <h3>CONTACT INFORMATION</h3>
      <ContactInformationWrapper {...props}>
        <div>
          {!props.favourite && process.env.GATBSY_SHOW_INVITE_BUTTON && (
            <InviteAFriend title={props.title} featureImg={props.featureImg} />
          )}
          {props.event && (
            <Event favourite={props.favourite}>
              {props.dates && (
                <Dates {...props}>
                  <CalendarIcon />
                  <p>{eventDate(props.dates)}</p>
                </Dates>
              )}
              {dayWithStartTime && !props.multiSessionTimes && (
                <Time {...props}>
                  <img src={clockIcon} alt="" />
                  <p>
                    {dayWithStartTime.startTime} - {dayWithStartTime.endTime}
                  </p>
                </Time>
              )}
              {props.time && !props.multiSessionTimes && (
                <Time {...props}>
                  <img src={clockIcon} alt="" />
                  <p>{props.time}</p>
                </Time>
              )}

              {props.multiSessionTimes && (
                <Time {...props}>
                  <img src={clockIcon} alt="" />
                  <p>Various times</p>
                </Time>
              )}
              <Details {...props}>
                {props.capacity && (
                  <p>
                    <span>Capacity: </span>
                    {props.capacity}
                  </p>
                )}
                {props.cost && (
                  <p>
                    <span>Cost: </span>
                    {props.cost}
                  </p>
                )}

                {props.age && (
                  <p>
                    <span>Age: </span>
                    {props.age}
                  </p>
                )}
              </Details>

              {props.bookTickets && !props.itinerary && (
                <Tickets {...props}>
                  <img src={ticketsIcon} alt="" />

                  {(props.bookingPopup && (
                    <a
                      className={`booking_popup ${
                        props.bookingPopup.rezdyClass
                      }`}
                      href={props.bookingPopup.rezdyHref}
                    >
                      Book your spot now
                    </a>
                  )) || (
                    <FancyLink to={props.bookTickets} rel="noopener noreferrer">
                      Book your spot now
                    </FancyLink>
                  )}
                </Tickets>
              )}

              {!props.shared && (
                <div style={{ marginLeft: -31, marginBottom: 30 }}>
                  <FavouriteButton
                    showText
                    id={props.databaseId}
                    listing={props.favourite}
                  />
                </div>
              )}
            </Event>
          )}
          <Contact {...props}>
            <RegionTag {...props.region} />
            {props.address && props.address.length ? (
              <Address {...props}>
                <img src={addressIcon} alt="" />
                <div>
                  <p>
                    {props.venue && <Venue>{props.venue}</Venue>}
                    {props.address}
                    {!props.hideMapLink && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://www.google.com.au/maps/place/${encodeURIComponent(
                          props.address
                        )}`}
                      >
                        Get directions
                      </a>
                    )}
                  </p>
                </div>
              </Address>
            ) : null}

            {props.openingHours && props.openingHours.length > 0 && (
              <Hours {...props}>
                <img src={clockIcon} alt="" />
                {hasAll.length > 0 &&
                  allDays.map(function(day, i) {
                    return (
                      <h5 key={i}>
                        <span>{day.day === 'ALL' ? 'DAILY' : day.day}: </span>
                        <Info>{day.times.join(', ')}</Info>
                      </h5>
                    )
                  })}
                {!hasAll.length > 0 &&
                  props.openingHours.map(function(day, i) {
                    return (
                      <h5 key={i}>
                        <span>{day.day}:</span> <Info>{day.times}</Info>
                      </h5>
                    )
                  })}
              </Hours>
            )}

            {props.favourite && props.dates && (
              <Dates {...props}>
                <CalendarIcon />
                <p>{eventDate(props.dates)}</p>
                <div style={{ paddingBottom: '10px' }} />
              </Dates>
            )}

            {!props.event && !props.shared && (
              <div
                style={{
                  marginLeft: -31,
                  marginBottom: props.favourite ? 0 : 10,
                }}
              >
                <FavouriteButton
                  showText
                  id={props.databaseId}
                  listing={props.favourite}
                />
              </div>
            )}

            {props.ticketsFrom && (
              <Tickets {...props}>
                <img src={ticketsIcon} alt="" />
                <p>Tickets from {props.ticketsFrom}</p>
              </Tickets>
            )}

            {props.website && props.itinerary && (
              <MoreInfo {...props}>
                <img src={cursorIcon} alt="" />

                <p>
                  <FancyLink rel="noopener noreferrer" to={props.website}>
                    Visit website
                  </FancyLink>
                </p>
              </MoreInfo>
            )}

            {props.path && (
              <MoreInfo {...props}>
                <img src={cursorIcon} alt="" />

                <p>
                  <FancyLink to={props.path}>Discover more</FancyLink>
                </p>
              </MoreInfo>
            )}

            {props.accommodation && (
              <MoreInfo {...props}>
                <img src={cursorIcon} alt="" />

                <p>
                  <FancyLink rel="noopener noreferrer" to={props.accommodation}>
                    View accommodation options
                  </FancyLink>
                </p>
              </MoreInfo>
            )}

            {props.downloadItinerary && (
              <ItineraryFile {...props}>
                <img src={fileIcon} alt="" />

                <p>
                  <FancyLink
                    rel="noopener noreferrer"
                    to={props.downloadItinerary}
                  >
                    Download this itinerary as a PDF
                  </FancyLink>
                </p>
              </ItineraryFile>
            )}

            {props.mailItinerary && (
              <ItineraryEmail {...props}>
                <img src={mailIcon} alt="" />

                <p>
                  <FancyLink to={props.mailItinerary}>
                    Contact us for a tailored itinerary
                  </FancyLink>
                </p>
              </ItineraryEmail>
            )}

            {(props.phone || props.website || props.email) &&
              !props.operators &&
              !props.itinerary && (
                <Contacting {...props}>
                  {props.phone && (
                    <h5>
                      P{' '}
                      <Info
                        as="a"
                        href={`tel:${props.phone.replace(/\s/g, '')}`}
                      >
                        {props.phone}
                      </Info>
                    </h5>
                  )}

                  {props.website && (
                    <h5>
                      W{' '}
                      <WebsiteInfo
                        target="_link"
                        rel="noopener noreferrer"
                        as="a"
                        href={checkSite(props.website)}
                      >
                        {textTruncate(props.website, 27, '...')}
                      </WebsiteInfo>
                    </h5>
                  )}

                  {props.email && (
                    <h5>
                      E{' '}
                      <WebsiteInfo
                        as="a"
                        rel="noopener noreferrer"
                        href={`mailto:${props.email}`}
                      >
                        {props.email}
                      </WebsiteInfo>
                    </h5>
                  )}
                </Contacting>
              )}
          </Contact>
          {props.operators && (props.email || props.website) && (
            <Links {...props}>
              {props.email && (
                <h5>
                  <a rel="noopener noreferrer" href={`mailto:${props.email}`}>
                    Email enquiries
                  </a>
                </h5>
              )}
              {props.website && (
                <h5>
                  <FancyLink rel="noopener noreferrer" to={props.website}>
                    Visit website
                  </FancyLink>
                </h5>
              )}
            </Links>
          )}
          {props.pricing && (
            <Pricing>
              <h5>Indicative Pricing:</h5>
              <p>{props.pricing}</p>
            </Pricing>
          )}
        </div>
      </ContactInformationWrapper>
    </ContactInformationContainer>
  )
}

export default ContactInformation
