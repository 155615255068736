import React, { useState } from 'react'
import {
  InviteButton,
  InviteFormWrapper,
  Header,
  MainSection,
  Visual,
  Disclaimer,
  Form,
  InputWrapper,
  SpreadIt,
  Close,
  Sent,
  ErrorMessage,
} from './style'
import { gql, useMutation } from '@apollo/client'
import { Link } from 'gatsby'
import { ReactComponent as Plane } from '../../../images/plane.svg'
import { ReactComponent as Facebook } from '../../../images/facebook-nav.svg'
import { ReactComponent as Cross } from '../../../images/white-close.svg'
import { Formik } from 'formik'
import { useEscHandler } from '../../../utils/helpers'
import { useStaticQuery, graphql } from 'gatsby'

import { Modal } from '../../Modal'
import AvenueImg from '../../AvenueImg/AvenueImg'

const CREATE_INVITE = gql`
  mutation(
    $friendsEmail: String
    $friendsName: String
    $email: String
    $postcode: String
    $name: String
    $url: String
    $title: String
    $image: String
  ) {
    createEventInvite(
      input: {
        clientMutationId: "createEventInvite"
        email: $email
        name: $name
        postcode: $postcode
        friendsName: $friendsName
        friendsEmail: $friendsEmail
        url: $url
        title: $title
        image: $image
      }
    ) {
      data
      success
    }
  }
`
const InviteAFriend = ({ title, featureImg }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [createInvite, { data }] = useMutation(CREATE_INVITE)

  const { bgImg } = useStaticQuery(
    graphql`
      query {
        bgImg: file(relativePath: { eq: "invite-a-friend-bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 600, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `
  )

  function fbShare(url, title, descr, winWidth, winHeight) {
    var winTop = window.screen.height / 2 - winHeight / 2
    var winLeft = window.screen.width / 2 - winWidth / 2
    window.open(
      'http://www.facebook.com/sharer.php?u=' + url,
      'sharer',
      'top=' +
        winTop +
        ',left=' +
        winLeft +
        ',toolbar=0,status=0,width=' +
        winWidth +
        ',height=' +
        winHeight
    )
  }

  useEscHandler(() => setIsOpen(false))

  return (
    <>
      <InviteButton onClick={() => setIsOpen(true)}>
        <h3>Invite A Friend & Win</h3>
        <Plane />
      </InviteButton>

      <Modal isOpen={isOpen}>
        <InviteFormWrapper>
          <Header>
            <AvenueImg fluid={bgImg.childImageSharp.fluid} />
            <h3>Invite A Friend & Win</h3>
            <Close onClick={() => setIsOpen(false)}>
              <Cross />
            </Close>
          </Header>
          <MainSection>
            <p className="bigger">
              This autumn <span>We Are Inviting</span> you to experience
              Ballarat at its best with those you love.
            </p>

            <Visual>
              <AvenueImg fluid={featureImg} />
              {title}
            </Visual>

            <Formik
              initialValues={{
                name: '',
                email: '',
                postcode: '',
                friendsName: '',
                friendsEmail: '',
              }}
              validateOnChange={false}
              validate={values => {
                let errors = {}

                if (!values.email) {
                  errors.email = 'Please enter a valid email address'
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Please enter a valid email address'
                }

                if (!values.friendsEmail) {
                  errors.friendsEmail = 'Please enter a valid email address'
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                    values.friendsEmail
                  )
                ) {
                  errors.friendsEmail = 'Please enter a valid email address'
                }

                if (!values.name) {
                  errors.name = 'Please enter a name'
                }
                if (!values.postcode) {
                  errors.postcode = 'Please enter a postcode'
                }

                if (!values.friendsName) {
                  errors.friendsName = 'Please enter a name'
                }
                if (!values.postcode) {
                  errors.postcode = 'Please enter a postcode'
                }

                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(true)
                }, 400)
                console.log('values:', values)

                const {
                  email,
                  name,
                  postcode,
                  friendsEmail,
                  friendsName,
                } = values

                createInvite({
                  variables: {
                    email,
                    name,
                    postcode,
                    friendsEmail,
                    friendsName,
                    url: window.location.href,
                    title,
                    image: featureImg.sourceUrl,
                  },
                })

                // createSignup({
                //   variables: {
                //     email: email,
                //     name: fullName,
                //     postcode: postcode,
                //     tag: props.tag || '',
                //   },
                // })
              }}
            >
              {({
                values,
                handleChange,
                errors,
                handleSubmit,
                isSubmitting,
                validateForm,
              }) => (
                <>
                  <Form onSubmit={handleSubmit} disabled={isSubmitting}>
                    <h3>YOUR DETAILS *</h3>

                    <InputWrapper disabled={isSubmitting}>
                      <input
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        placeholder="Your full name…"
                      />
                      {errors.name && (
                        <ErrorMessage name="name">{errors.name}</ErrorMessage>
                      )}
                      <input
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        placeholder="Your email address…"
                      />

                      {errors.email && (
                        <ErrorMessage name="email">{errors.email}</ErrorMessage>
                      )}
                      <input
                        type="text"
                        name="postcode"
                        value={values.postcode}
                        onChange={handleChange}
                        placeholder="Your postcode…"
                      />
                      {errors.postcode && (
                        <ErrorMessage name="postcode">
                          {errors.postcode}
                        </ErrorMessage>
                      )}
                    </InputWrapper>

                    <Disclaimer className="border">
                      Your personal information is being collected by the City
                      of Ballarat for research purposes as well as processing
                      your entry into this competition. Your information will be
                      stored in the City of Ballarat's customer database and
                      used to draw the winner for the competition, and for
                      marketing and research purposes. For further information
                      on how your personal information is handled, refer to the
                      City of Ballarat's Privacy Policy{' '}
                      <Link to="/privacy-policy/">here.</Link>
                    </Disclaimer>

                    <h3>YOUR FRIEND'S DETAILS *</h3>
                    <InputWrapper disabled={isSubmitting}>
                      <input
                        type="text"
                        name="friendsName"
                        value={values.friendsName}
                        onChange={handleChange}
                        placeholder="Your friend's name…"
                      />
                      {errors.friendsName && (
                        <ErrorMessage name="friendsName">
                          {errors.friendsName}
                        </ErrorMessage>
                      )}
                      <input
                        type="text"
                        name="friendsEmail"
                        value={values.friendsEmail}
                        onChange={handleChange}
                        placeholder="Your friend's email address…"
                      />

                      {errors.friendsEmail && (
                        <ErrorMessage name="friendsEmail">
                          {errors.friendsEmail}
                        </ErrorMessage>
                      )}
                    </InputWrapper>

                    <Disclaimer>
                      Your friend's details will not be collected by the City of
                      Ballarat.
                    </Disclaimer>

                    <InviteButton
                      className="alt"
                      type="submit"
                      disabled={isSubmitting}
                      onClick={() => {
                        validateForm()
                      }}
                    >
                      <h3>Send Invite</h3>
                      <Plane />
                    </InviteButton>
                  </Form>
                  <Sent disabled={isSubmitting}>
                    Your invitation has been sent!
                  </Sent>
                </>
              )}
            </Formik>

            <SpreadIt>
              <h4>
                You can also spread the word on{' '}
                <a
                  href="#"
                  onClick={() => {
                    fbShare(
                      window.location.href,
                      'Fb Share',
                      'Facebook share popup',
                      520,
                      350
                    )
                  }}
                >
                  facebook
                </a>
                <Facebook />
              </h4>
            </SpreadIt>
          </MainSection>
        </InviteFormWrapper>
      </Modal>
    </>
  )
}

export default InviteAFriend
