import styled from 'styled-components'

export const ContactInformationContainer = styled.div`
  margin: ${props =>
    props.centered ? '0 auto' : props.generic ? '45px auto' : 'null'};

  border-top: ${props => (props.generic ? '1px solid #889390' : 'none')};

  padding: ${props => (props.generic ? '50px 8px 40px 8px' : null)};

  @media (min-width: 768px) {
    max-width: ${props => (props.itinerary || props.generic ? '780px' : null)};
    padding: ${props => (props.generic ? '70px 8px 80px 8px' : null)};
  }

  h3 {
    font-size: 18px;
    margin-bottom: 30px;
    display: ${props => (props.generic ? 'block' : 'none')};
    @media (min-width: 768px) {
      font-size: 22px;
      margin-bottom: 44px;
    }
  }

  a {
    :hover {
      color: #a55331;
    }
  }
`

export const ContactInformationWrapper = styled.div`
  padding-left: ${props =>
    props.itinerary ? ' 33px' : props.generic ? '30px' : '38px'};

  @media (min-width: 1024px) {
    padding-left: ${props =>
      props.itinerary ? ' 33px' : props.generic ? '30px' : '0'};
  }

  img {
    padding-right: 12px;
    margin: 0;
  }

  div {
    div {
      @media (min-width: 768px) {
        max-width: ${props => (props.generic ? '780px' : null)};
      }
    }

    h5 {
      position: relative;
      font-weight: 500;
      font-size: 14px;
      color: #545c52;
      margin-bottom: 10px;

      span {
        position: absolute;
        top: -1px;
        left: 30px;
      }
    }

    p {
      font-size: ${props =>
        props.favourite || props.itinerary ? '14px' : '16px'};
      margin: 0;
      line-height: 168%;

      @media (min-width: 768px) {
        font-size: ${props => (props.itinerary ? '16px' : '16px')};
      }
    }

    a {
      font-weight: 500;
      font-size: ${props => (props.itinerary ? '14px' : '16px')};
      text-decoration: none;
      color: #212c1f;

      @media (min-width: 768px) {
        font-size: ${props => (props.itinerary ? '16px' : null)};
      }
    }
  }
`

export const Info = styled.span`
  font-size: 16px;
  color: #545c52 !important;
  font-weight: 300 !important;
  padding-left: 11px;
  text-transform: none;
`

export const Event = styled.div`
  border-bottom: ${props =>
    props.itinerary ? 'none' : '1px solid rgba(0, 0, 0, 0.3)'};
  padding-bottom: ${props =>
    props.itinerary || props.favourite ? '0' : '15px'};
  margin-bottom: ${props =>
    props.itinerary || props.favourite ? '0px' : '30px'};
  line-height: 100%;
`
export const Dates = styled.div`
  margin-bottom: 15px;
  /* display:grid;
			grid-template-columns: auto 1fr; */
  align-items: center;
  position: relative;

  svg {
    position: absolute;
    top: 4px;
    top: ${props => props.favourite && '2px'};
    left: ${props => (props.itinerary ? '-33px' : '-30px')};
    width: 30px;
    padding-right: 12px;
    stroke: #8b3a19;

    @media (min-width: 1024px) {
      top: 4px;
    }
  }

  img {
    position: absolute;
    top: 2px;
    left: ${props => (props.itinerary ? '-33px' : '-30px')};
  }
`

export const Time = styled(Dates)`
  img {
    top: 4px;
  }
`
export const Details = styled.div`
  margin-bottom: ${props =>
    props.capacity ? '20px' : props.cost ? '20px' : props.age ? '20px' : '0'};
  span {
    color: #545c52;
  }
`
export const Tickets = styled(Dates)`
  margin-bottom: ${props => (props.itinerary ? '16px' : '20px')};
  line-height: 166%;

  img {
    top: 5px;
  }

  a {
    /* padding-left: 8px; */
    @media (min-width: 768px) {
      padding-left: 0px;
    }
  }
`

export const Contact = styled(Event)`
  padding-bottom: ${props =>
    props.itinerary || props.favourite ? '0' : '8px'};
  border-bottom: ${props =>
    (props.generic || (!props.website && !props.email)) && 'none'};

  .region {
    margin-left: -30px;
    margin-bottom: 20px;
    font-size: 14px;
    svg {
      margin-right: 15px;
    }

    @media (min-width: 1024px) {
      font-size: 16px;
    }
  }
`
export const Venue = styled.span`
  font-weight: 500;
  display: block;
  line-height: 1.5;
  margin-bottom: 8px;
`

export const Address = styled.div`
  position: relative;

  img {
    position: absolute;
    top: 2px;
    left: ${props => (props.itinerary ? '-33px' : '-30px')};
  }

  max-width: ${props => (props.itinerary ? '100%' : '320px')};
  margin-bottom: ${props => (props.itinerary ? '16px' : '20px')};

  p {
    max-width: ${props => (props.itinerary ? '100%' : '100%')};
  }

  div > p > a {
    display: ${props => (props.itinerary ? 'none' : 'block')};
    white-space: nowrap;
    ${props =>
      props.generic &&
      `@media(min-width: 768px) {
      display:inline;

    padding-left: 23px;
    position:relative;
    /* border-left: 1px solid #B9BAB4; */
    margin-left: 23px;

    ::after {
			content: "";
      position: absolute;
      height: 20px;
      width: 1px;
      left:0;
      top:0;
      background: #B9BAB4;

      display: block;
    }

  }`};
  }

  @media print {
    margin-bottom: 10;
    padding: 0;
    width: 100%;

    ::after {
      position: relative;
      top: 10px;
      border-bottom: 1px solid #b9bab4;
      display: block;
      content: '';
      height: 1px;
      width: calc(100vw - 30px);
      transform: translateX(-38px);
    }
  }
`

export const Hours = styled(Address)`
  margin-bottom: 30px;

  > h5 {
    display: flex;

    > span {
      position: relative !important;
      left: 0 !important;

      :first-of-type {
        width: 35px;
      }
    }
  }

  @media print {
    break-inside: avoid;

    ::after {
      border: none !important;
    }
  }
`

export const MoreInfo = styled(Address)`
  img {
    top: 2px;
  }
`

export const ItineraryFile = styled(MoreInfo)`
  margin-bottom: 0;

  img {
    @media (min-width: 768px) {
      width: 31px;
    }
  }

  p {
    a {
      font-size: 14px;
      @media (min-width: 768px) {
        font-size: 18px;
      }
    }
  }
`

export const ItineraryEmail = styled(ItineraryFile)`
  img {
    top: 4px;
  }
`

export const Contacting = styled(Hours)`
  h5 {
    :hover {
      /* color: #a55331;	 */

      > a {
        color: #a55331 !important;
      }
    }
  }
`

export const WebsiteInfo = styled(Info)`
  padding-left: 8px;
`

export const Links = styled.div`
  margin-bottom: 30px;
  padding-bottom: 25px;
  border-bottom: ${props =>
    props.pricing ? '1px solid rgba(0, 0, 0, 0.3)' : 'none'};

  h5 {
    text-transform: none;
  }
`

export const Pricing = styled.div`
  padding-bottom: 30px;
  h5 {
    text-transform: none;
    font-size: 16px !important;
  }
`
